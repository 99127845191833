import moment from "moment";

//秒数转化为几分几秒
export function formatSeconds(value) {
  if(value === undefined || value === ''){
    return '';
  }
  let minutes = Math.floor(parseFloat(value) / 60);
  let remainingSeconds = parseFloat(value) % 60;
  if (minutes === 0) {
    return `${remainingSeconds.toFixed(0)}秒`;
  } else {
    return `${minutes}分${remainingSeconds.toFixed(0)}秒`;
  }
}

export function formatTime(value,pattern = "YYYY-MM-DD HH:mm:ss") {
  return moment(new Date(value)).format(pattern);
}

export function determineExists(value) {
  if (value === null || value === undefined || value === "") {
    return false;
  } else {
    return true;
  }
}


