export const sexMap = {
  0: "男",
  1: "女",
};

//特殊人群
export const specialMap = {
  1: "正常人群",
  2: "聋哑人",
  3: "残疾人",
  4: "其他特殊人群",
};

//骑手状态
export const statusMap = {
  1: "启用",
  2: "禁用",
  3: "拉黑",
};

//支付方式
export const payMap = {
  1: "微信支付",
  2: "支付宝",
  3: "银行转账",
  4: "其他支付",
};

//结算方式
export const settleMap = {
  1: "按次结算",
  2: "按周结算",
  3: "按月结算",
  4: "按季结算",
};

//配送状态
export const freightStatusMap = {
  1: "待接单",
  2: "已接单",
  3: "已到店",
  4: "配送中",
  5: "已完成",
  6: "已取消",
};

//制单类型
export const freightTypeMap = {
  1: "系统制单",
  2: "手工制单",
};

//是否超时
export const timeoutMap = {
  0: "否",
  1: "是",
};

//取货单单据状态
export const pickingStateMap = {
  1: "制单中",
  2: "待出货",
  3: "已出货",
  4: "已取消",
  5: "部分核销",
  6: "已核销",
};
